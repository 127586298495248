import React from 'react';
import styled from 'styled-components';
import { colors } from '../../theme';
import VotingClauseJaworze from './VotingClauseJaworze';
import VotingClauseCarbonet from './VotingClauseCarbonet';

const ClauseContainer = styled.div`
  margin-top: 2rem;
  ol {
    li {
      margin: 0.5rem 0;

      ol {
        counter-reset: item;
        list-style-type: none;
      }
      li {
        display: block;
      }
      li:before {
        content: counter(item, lower-alpha) ')  ';
        counter-increment: item;
      }
    }
  }
`;

export const ClauseHeader = styled.p`
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  padding: 1rem 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${colors.lightBlue};
  }
`;

const dummyOrganization = { name: '', person: '', clause: '' };

export interface OrganizationType {
  name: string;
  person: string;
  clause?: string;
}

interface Props {
  organization: OrganizationType;
}

const VotingClause: React.FC<Props> = (props) => {
  const { organization: _organization } = props;

  const organization = _organization?.name ? _organization : dummyOrganization;

  return (
    <ClauseContainer>
      {organization.clause === 'carbonet' ? (
        <VotingClauseCarbonet organization={organization} />
      ) : (
        <VotingClauseJaworze organization={organization} />
      )}
    </ClauseContainer>
  );
};

export default VotingClause;
