import {
  ProjectAnswerInputsType,
  ProjectQuestionInputsType,
  Step1InputsType,
  Step2InputsType,
} from './types';

export const defaultProjectImage = '/images/placeholder-project-image.png';
export const defaultAnswerImage = '/images/placeholder-project-image.png';

// ?? - https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator

export const getStep1DefaultValues = (values?: Step1InputsType | undefined): Step1InputsType => {
  return {
    image: values?.image,
    image_id: values?.image_id ?? undefined,
    name: values?.name ?? '',
    default_answer: values?.default_answer ?? false,
    instruction_checkbox: values?.instruction_checkbox ?? false,
    voting_ordinance: values?.voting_ordinance ?? '',
    voting_card_instruction: values?.voting_card_instruction ?? '',
    questions: values?.questions ?? [getProjectQuestionDefaultValue()],
  };
};

export const getProjectQuestionDefaultValue = (): ProjectQuestionInputsType => {
  return {
    question: '',
    min_required_answers: 1,
    max_required_answers: 1,
    answers: [getProjectAnswerDefaultValue()],
    selected_default_answer: '',
  };
};

export const getProjectAnswerDefaultValue = (): ProjectAnswerInputsType => {
  return {
    text: '',
    link: '',
    project_data: {
      value: undefined,
      currency: 'PLN',
      short_description: '',
    },
    image: undefined,
    image_id: undefined,
    is_default: false,
  };
};

export const getStep2DefaultValues = (values?: Step2InputsType | undefined): Step2InputsType => {
  return {
    time: {
      value: values?.time?.value ?? '',
      start_date: values?.time?.start_date ?? new Date(),
      end_date: values?.time?.end_date ?? new Date(),
    },

    is_protocol_label_name: values?.is_protocol_label_name ?? false,
    is_protocol_label_nick: values?.is_protocol_label_nick ?? false,
    is_protocol_anonymized: true,

    is_results_only_for_owner: values?.is_results_only_for_owner ?? false,
    is_voting_card_vote: values?.is_voting_card_vote ?? false,
    show_on_page: values?.show_on_page ?? false,
    show_results_after_voted: values?.show_results_after_voted ?? false,
    send_vote_confirmations: values?.send_vote_confirmations ?? false,

    instruction_video_checkbox: values?.instruction_video_checkbox ?? false,
    instruction_link: values?.instruction_link ?? 'https://www.youtube.com/watch?v=An50vLxW9JM',

    require_to_vote_email: values?.require_to_vote_email ?? false,
    require_to_vote_phone: values?.require_to_vote_phone ?? false,
    require_to_vote_qr: values?.require_to_vote_qr ?? false,
    require_to_vote_name: values?.require_to_vote_name ?? false,
    require_to_vote_address: values?.require_to_vote_address ?? false,
    require_to_vote_min_age: values?.require_to_vote_min_age ?? 13,

    require_to_vote_min_age_checkbox: values?.require_to_vote_min_age_checkbox ?? false,
  };
};
