import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { Step2InputsType } from './utils/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectStep1Schema, ProjectStep2Schema } from './utils/validation';
import { Center, FlexContainer, Form } from '../../../components/Containers/containers';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import TimeSettings from './Step2Components/TimeSettings';
import TypeSettings from './Step2Components/TypeSettings';
import AdditionalSettings from './Step2Components/AdditionalSettings';
import AuthSettings from './Step2Components/AuthSettings';
import { getStep2DefaultValues } from './utils/defaultValues';
import { updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';
import { AbsoluteDrawerBackButton } from '../../../components/DrawerBackButton';
import { history } from '../../../utils/history';
import { getCreateProjectPath } from '../../../constants/paths.constants';
import useProjectDataValidation from './utils/useProjectDataValidation';
import ProtocolType from './Step2Components/ProtocolType';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import useSaveFormOnChangeToStore from '../../../utils/useSaveFormOnChangeToStore';

interface Props {
  newSurvey: any;
  updateNewSurveyData(data: any): any;
}

const ProjectStep2: React.FC<Props> = (props) => {
  const { newSurvey, updateNewSurveyData } = props;
  const formMethods: UseFormReturn = useForm<Step2InputsType>({
    resolver: yupResolver(ProjectStep2Schema),
    defaultValues: getStep2DefaultValues(newSurvey),
  });

  const { handleSubmit, getValues, control } = formMethods;

  useProjectDataValidation({
    data: newSurvey,
    schemas: [ProjectStep1Schema],
    notValidCallback: () => {
      history.replace(getCreateProjectPath({ step: 1 }));
    },
  });

  useSaveFormOnChangeToStore({
    control,
    fun: updateNewSurveyData,
  });

  const onSubmit = (data: Step2InputsType) => {
    updateNewSurveyData(data);
    history.push(getCreateProjectPath({ step: 3 }));
  };

  return (
    <>
      <AbsoluteDrawerBackButton
        isBackVisible={true}
        onClick={() => {
          updateNewSurveyData(getValues());
          history.push(getCreateProjectPath({ step: 1 }));
        }}
      />
      <FlexContainer>
        <Center>
          <SurveyTypeHeader type={'project'} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TimeSettings formMethods={formMethods} />
            <ProtocolType formMethods={formMethods} />
            <AdditionalSettings formMethods={formMethods} />
            <AuthSettings formMethods={formMethods} />
            <PrimarySmallButton>
              <Trans>Further</Trans>
            </PrimarySmallButton>
          </Form>
        </Center>
      </FlexContainer>
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return { newSurvey: state.surveys.newSurvey };
}

export default withRouter(connect(mapStateToProps, { updateNewSurveyData })(ProjectStep2));
