import React, { useState } from 'react';
import SimpleModal from '../../../../components/Modal/SimpleModal';
import { InfoIcon } from '../../../../components/Modal/ModalTitleIcons';
import { Center } from '../../../../components/Containers/containers';
import { Image, InputValue, Label, LabelValueContainer } from './StyledComponents';
import { ActionButton as InfoButton } from '../StyledComponents';
import { InfoRounded } from '@material-ui/icons';
import { ProjectAnswerInputsType } from '../utils/types';
import { defaultAnswerImage } from '../utils/defaultValues';
import { Trans } from 'react-i18next';

interface Props {
  answer: ProjectAnswerInputsType;
  is_default?: boolean;
}

const AnswerInputPreview: React.FC<Props> = (props) => {
  const { answer, is_default = false } = props;
  const [isOpenProjectInfo, setIsOpenProjectInfo] = useState<boolean>(false);

  return (
    <>
      <SimpleModal open={isOpenProjectInfo} setOpen={setIsOpenProjectInfo} iconComponent={InfoIcon}>
        <Center>
          {answer.link && (
            <LabelValueContainer>
              <Label>Link do projektu</Label>
              <InputValue>{answer.link}</InputValue>
            </LabelValueContainer>
          )}

          {typeof answer?.project_data?.value === 'number' && (
            <LabelValueContainer>
              <Label>
                <Trans>TaskAmountLabel</Trans>
              </Label>
              <InputValue>
                {answer.project_data?.value} {answer.project_data?.currency}
              </InputValue>
            </LabelValueContainer>
          )}

          {answer.project_data?.short_description && (
            <LabelValueContainer>
              <Label>Skrócony opis projektu</Label>
              <InputValue>{answer.project_data?.short_description}</InputValue>
            </LabelValueContainer>
          )}

          <LabelValueContainer>
            <Label>Miniaturka do zadania</Label>
            <Image
              bgImage={
                (answer.image && answer.image[0] && URL.createObjectURL(answer.image[0])) ||
                defaultAnswerImage
              }
            />
          </LabelValueContainer>
        </Center>
      </SimpleModal>
      <InputValue>
        {is_default ? '(Domyślna odpowiedź) ' : ''}
        {answer.text}{' '}
        <InfoButton onClick={() => setIsOpenProjectInfo(true)}>
          <InfoRounded />
        </InfoButton>
      </InputValue>
    </>
  );
};

export default AnswerInputPreview;
