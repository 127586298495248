import React from 'react';
import { FlexContainer, Center, Form } from '../../../components/Containers/containers';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import InputImage from '../../../components/Forms/InputImage';
import { FieldError, useForm, useWatch } from 'react-hook-form';
import InputText from '../../../components/Forms/InputText';
import Checkbox from '../../../components/Forms/Checkbox';
import InputTextarea from '../../../components/Forms/InputTextarea';
import { Trans } from 'react-i18next';
import { PrimarySmallButton } from '../../../components/Buttons/buttons';
import { FormLabel } from '../../../components/Forms/forms';
import QuestionsFieldArray from './Step1Components/QuestionsFieldArray';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { defaultProjectImage, getStep1DefaultValues } from './utils/defaultValues';
import { Step1InputsType } from './utils/types';
import { ProjectStep1Schema } from './utils/validation';
import { updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';
import { history } from '../../../utils/history';
import { getCreateProjectPath } from '../../../constants/paths.constants';
import useSaveFormOnChangeToStore from '../../../utils/useSaveFormOnChangeToStore';

interface Props {
  newSurvey: any;
  updateNewSurveyData(data: any): void;
}

const ProjectStep1: React.FC<Props> = (props) => {
  const { newSurvey, updateNewSurveyData } = props;
  const formMethods = useForm<Step1InputsType>({
    resolver: yupResolver(ProjectStep1Schema),
    defaultValues: getStep1DefaultValues(newSurvey),
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
  } = formMethods;

  useSaveFormOnChangeToStore({
    control,
    fun: updateNewSurveyData,
  });

  const watchInstructionCheckbox = useWatch({
    name: 'instruction_checkbox',
    control,
  });

  const onSubmit = (data: Step1InputsType) => {
    updateNewSurveyData(data);
    history.push(getCreateProjectPath({ step: 2 }));
  };

  const getErrorMessageForDefaultAnswerCheckbox = (): FieldError | undefined => {
    if (errors?.questions?.some((questionError: any) => !!questionError?.selected_default_answer)) {
      return { type: 'default_answer', message: 'Zaznacz domyślną odpowiedź dla każdego pytania' };
    }
    return errors.default_answer;
  };

  return (
    <FlexContainer>
      <Center>
        <SurveyTypeHeader type={'project'} />
        <Form autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
          <FormLabel formType={'survey'}>Zdjęcie profilowe projektu</FormLabel>
          <InputImage
            name={`image`}
            formRef={register}
            formState={formState}
            control={control}
            fieldError={errors.image}
            helperText={'Rekomendowany format 16:9 lub 4:3. Wymagane rozszerzenie jpg lub png.'}
            setValue={setValue}
            defaultImage={defaultProjectImage}
          />
          <InputText
            label={'Nazwa głosowania'}
            name={'name'}
            formRef={register}
            formState={formState}
            formType={'survey'}
            fieldError={errors.name}
          />
          <div style={{ width: '90%' }}>
            <Checkbox
              label={'Uwzględnij brak odpowiedzi osoby uprawnionej do głosowania w wynikach'}
              name={'default_answer'}
              formRef={register}
              formState={formState}
              popoverText={
                'W przypadku gdy osoba zaproszona do głosowania nie odda głosu, zostanie doliczona w wynikach do wybranej przez organizatora odpowiedzi, np. "Brak głosu", "Nie mam zdania", etc. Jeśli zaznaczysz tę opcję, musisz wybrać domyślną odpowiedź dla każdego pytania.'
              }
              style={{ marginTop: '2rem' }}
              fieldError={getErrorMessageForDefaultAnswerCheckbox()}
            />
          </div>
          <div style={{ width: '90%' }}>
            <Checkbox
              label={'Dodaj instrukcję głosowania'}
              name={'instruction_checkbox'}
              formRef={register}
              formState={formState}
              style={{ marginTop: '2rem' }}
              fieldError={errors.instruction_checkbox}
            />
          </div>
          {watchInstructionCheckbox && (
            <>
              <InputText
                label={'Nr zarządzenia bądź nr uchwały'}
                name={'voting_ordinance'}
                formRef={register}
                formState={formState}
                formType={'survey'}
                fieldError={errors.voting_ordinance}
              />
              <InputTextarea
                label={'Instrukcja głosowania'}
                name={'voting_card_instruction'}
                fieldError={errors.voting_card_instruction}
                formRef={register}
                formState={formState}
                formType={'survey'}
                control={control}
                schema={ProjectStep1Schema}
              />
            </>
          )}

          <QuestionsFieldArray
            name={'questions'}
            formMethods={formMethods}
            schema={ProjectStep1Schema}
          />

          <PrimarySmallButton>
            <Trans>Further</Trans>
          </PrimarySmallButton>
        </Form>
      </Center>
    </FlexContainer>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return { newSurvey: state.surveys.newSurvey };
}

export default withRouter(connect(mapStateToProps, { updateNewSurveyData })(ProjectStep1));
