import axios from 'axios';
import { showNotification } from '../../utils/taost.config';

export async function postMedia(media: any) {
  const formData = new FormData();
  formData.append('image', media);
  let url = process.env.REACT_APP_API_URL + `/media/`;
  return axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      showNotification({
        response: error.response,
        type: 'error',
      });
    });
}

export async function postCVS(file: any) {
  const formData = new FormData();
  formData.append('file', file);
  let url = process.env.REACT_APP_API_URL + `/user/import_csv/`;
  return axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      showNotification({
        response: error.response,
        type: 'error',
      });
    });
}
