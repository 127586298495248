import React, { FC } from 'react';
import { ClauseHeader, OrganizationType } from './VotingClause';

interface Props {
  organization: OrganizationType;
}

const VotingClauseCarbonet: FC<Props> = ({ organization }) => {
  return (
    <>
      <ClauseHeader>Klauzula informacyjna o przetwarzaniu danych osobowych</ClauseHeader>W związku z
      przetwarzaniem Pani/Pana danych osobowych przez Operatora aplikacji iVoting Free Carbonet.pl
      Sp. z o.o., informujemy na podstawie art.13 ust. 1 i ust. 2 rozporządzenia Parlamentu
      Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
      fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
      danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (Dz. Urz.
      UE L z 04.05.2016 r., nr 119, s.1), zwane dalej w skrócie „RODO”:
      <ol>
        <li>
          Administratorem Pani/Pana danych osobowych jest Operator iVoting Free Carbonet.pl Sp. z
          o.o., z siedzibą 43-300 Bielsko-Biała, plac Cieszyńska 365.
        </li>
        <li>
          Z Inspektorem Ochrony Danych, może Pani/Pan skontaktować się w sprawach związanych z
          ochroną danych osobowych, w następujący sposób:
          <ol type={'a'}>
            <li>pod adresem poczty elektronicznej: iod@ivoting.pl,</li>
            <li>pisemnie na adres siedziby Administratora, </li>
            <li>telefonicznie: +48 577 940 710.</li>
          </ol>
        </li>
        <li>
          Przetwarzanie Pani/Pana danych odbywa się w związku z realizacją procedury konsultacji
          społecznych, badań opinii przez Internet w formie e-głosowań, e-ankiet, e-sondaży
          prowadzonych przez Operatora aplikacji iVoting Free oraz Organizatora głosowań{' '}
          {organization.name} {organization.person}
        </li>
        <li>
          Pani/Pana Dane osobowe mogą być przekazywane wydziałom jednostkom organizacyjnym
          {organization.name} {organization.person} w celu wykonywania zadań publicznych lub innym
          podmiotom, jeżeli przepis prawa nakłada na {organization.name} {organization.person} taki
          obowiązek.
        </li>
        <li>
          Pani/Pana dane osobowe będą przechowywane jedynie w okresie niezbędnym do spełnienia celu,
          dla którego zostały zebrane, a po tym czasie przez okres oraz w zakresie wymaganym przez
          przepisy powszechnie obowiązującego prawa. Po spełnieniu celu, dla którego Pani/Pana dane
          zostały zebrane, mogą one być przechowywane jedynie w celach archiwalnych, przez okres,
          który zostanie wyznaczony przede wszystkim na podstawie rozporządzenia Prezesa Rady
          Ministrów z dnia 18 stycznia 2011 r. w sprawie instrukcji kancelaryjnej, jednolitych
          rzeczowych wykazów akt oraz instrukcji w sprawie organizacji i zakresu działania archiwów
          zakładowych, chyba że przepisy szczególne stanowią inaczej.
        </li>
        <li>
          Na zasadach określonych przepisami RODO, posiada Pani/Pan prawo do: dostępu do swoich
          danych osobowych, sprostowania swoich danych osobowych, usunięcia swoich danych osobowych,
          ograniczenia przetwarzania swoich danych osobowych, do wniesienia sprzeciwu wobec
          przetwarzania Pani/Pana danych osobowych, do cofnięcia zgody na dalsze przetwarzanie
          danych osobowych, wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.
        </li>
        <li>
          Pani/Pana dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą
          profilowane.
        </li>
        <li>
          Usunięcie konto w aplikacji iVoting jest równoznaczne z automatycznym zapomnieniem w bazie
          danych Operatora aplikacji. Aby usunąć dane z bazy danych aplikacji należy zalogować się
          do aplikacji iVoting Free (www.app.ivoting.pl), kliknąć przycisk MENU w prawym górnym
          rogu, wybrać MÓJ PROFIL oraz kliknąć przycisk USUŃ KONTO. Użytkownik otrzyma na swój adres
          e-mail potwierdzenie usunięcia danych z bazy
        </li>
      </ol>
    </>
  );
};

export default VotingClauseCarbonet