import yup, { mixedTests } from '../../../../utils/yup/yupExtended';
import { ProjectAnswerInputsType } from './types';
import { TestContext } from 'yup';

// https://www.npmjs.com/package/yup

export const ProjectStep1Schema = yup.object().shape({
  image: yup
    .mixed()
    .test(
      mixedTests.fileExtension.name,
      mixedTests.fileExtension.message,
      mixedTests.fileExtension.fun(['jpg', 'jpeg', 'png'])
    )
    .test(mixedTests.fileSize.name, mixedTests.fileSize.message(10), mixedTests.fileSize.fun(10)),
  name: yup.string().required().max(100),
  default_answer: yup.boolean().nullable(),
  instruction_checkbox: yup.boolean().nullable(),
  voting_ordinance: yup
    .string()
    .max(100)
    .when('instruction_checkbox', {
      is: (instruction_checkbox: boolean) => instruction_checkbox,
      then: yup.string().required(),
    }),
  voting_card_instruction: yup
    .string()
    .max(400)
    .when('instruction_checkbox', {
      is: (instruction_checkbox: boolean) => instruction_checkbox,
      then: yup.string().required().max(400),
    }),
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().required().max(200),
      selected_default_answer: yup
        .string()
        .test(
          'selected_default_answer',
          'Zaznacz domyślną odpowiedź',
          function (this: any, value: any, context: TestContext) {
            // https://github.com/jquense/yup/issues/289#issuecomment-861867193
            const contextGeneric = context as unknown as {
              from: { value: Record<string, unknown> }[];
            };
            const { default_answer } = contextGeneric.from[1].value;
            return !default_answer || !!context.parent.selected_default_answer;
          }
        ),
      answers: yup.array().of(
        yup.object().shape({
          text: yup.string().required().max(300),
          link: yup.string().urlExtended(),
          image: yup
            .mixed()
            .test(
              mixedTests.fileExtension.name,
              mixedTests.fileExtension.message,
              mixedTests.fileExtension.fun(['jpg', 'jpeg', 'png'])
            )
            .test(
              mixedTests.fileSize.name,
              mixedTests.fileSize.message(10),
              mixedTests.fileSize.fun(10)
            ),
          project_data: yup.object().shape({
            value: yup
              .number()
              .min(0)
              // .maxDecimalDigits(2)
              .nullable()
              .transform((v, o) => {
                return v === '' || Number.isNaN(v) ? null : v;
              }),
            currency: yup.string().nullable(),
            short_description: yup.string().required().max(300),
          }),
        })
      ),
    })
  ),
});

export const ProjectStep2Schema = yup.object().shape({
  time: yup.object().shape({
    value: yup.string().required().nullable(),
    start_date: yup.date().when('value', {
      is: 'custom',
      then: (schema) =>
        schema
          .required()
          .max(
            yup.ref('end_date'),
            'Data rozpoczęcia nie może być późniejsza niż data zakończenia'
          ),
    }),
    end_date: yup.date().when('value', {
      is: 'custom',
      then: (schema) =>
        schema
          .required()
          .min(
            yup.ref('start_date'),
            'Data zakończenia nie może być wcześniejsza niż data rozpoczęcia'
          ),
    }),
  }),
  instruction_video_checkbox: yup.boolean().nullable(),
  instruction_link: yup
    .string()
    .urlExtended()
    .when('instruction_video_checkbox', {
      is: true,
      then: (schema) => schema.required(),
    }),
  is_protocol_label_name: yup.boolean().nullable(),
  is_protocol_label_nick: yup.boolean().nullable(),
  is_protocol_anonymized: yup.boolean().nullable(),
  is_results_only_for_owner: yup.boolean().nullable(),
});

export const ProjectStep3Schema = yup.object().shape({
  invited_groups: yup.array().of(yup.number()).min(1),
});
