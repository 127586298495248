import React, { FC, useEffect, useRef, useState } from 'react';
// import { RequestStatus } from '../../helpers/requestStatus';
import { getSurveysPath } from '../../constants/paths.constants';
import { useHistory } from 'react-router';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { toast } from 'react-toastify';
import {
  getNotifications,
  getNotificationsSummary,
} from '../../reducers/Notifications/notifications.actions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DeviceTypeEnum, FCMRegisterPayload } from '../../types/FCMRegisterPayload.types';
import NetworkService from '../../utils/network.service';
import store from '../../store';
import SimpleModal from '../../components/Modal/SimpleModal';
import { newVersionAvailableModalConfig } from '../../components/SWModal';
import { isInWebviewByDomain } from '../../utils/mobileHelper';

// todo: add types
type MobileMessagingProps = {
  children?: any;
  registerFCMDevice?: any;
  fcmDevice?: any;
  fcmDeviceFetchStatus?: null | string;
  loginFetchStatus?: any;
  updateFCMInfo?: any;
  user?: any;
  token?: string | null;
  getNotifications: any;
  getNotificationsSummary: any;
  logout?: () => void;
};

const ToastContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -8px;
  margin-bottom: -8px;

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const CustomToastWithLink = ({ url, text }: { url: string; text: string }) => (
  <ToastContainer>
    <a href={url}>{text}</a>
  </ToastContainer>
);

const envPWAVersion = process.env.REACT_APP_VERSION || '';

const MobileMessaging: FC<MobileMessagingProps> = (props) => {
  const {
    children,
    registerFCMDevice = () => {},
    fcmDevice,
    fcmDeviceFetchStatus,
    loginFetchStatus,
    updateFCMInfo = () => {},
    user,
    token,
    logout,
  } = props;

  const history = useHistory();
  const userRef = useRef(user);
  const interceptorInitRef = useRef(false);
  const { t } = useTranslation();
  const [updateModal, setUpdateModal] = useState({
    open: false,
    onClick: () => {},
    buttonText: 'Pobierz aplikację ze sklepu',
  });

  useEffect(() => {
    if (user) userRef.current = user;
  }, [user]);

  const callJs = (data: string) => {
    // to test purpose
    console.warn('Native mobile event', data);
    let parsedData;
    try {
      parsedData = JSON.parse(data);
    } catch (err) {
      console.error('Bridge - actions parsing error');
    }
    if (typeof parsedData == 'object') callActions(parsedData);
  };

  const nativeShare = (url: string) => {
    console.warn('Native share ', url);
    // @ts-ignore
    window.Android?.share(url);
    // @ts-ignore
    window.webkit?.messageHandlers?.iOS?.postMessage({ type: 'share', url });
  };

  const callActions = (data: { type: string; payload: FCMRegisterPayload }) => {
    const { type, payload } = data;

    if (type === 'registerDevice') {
      if (loginFetchStatus === REQUEST_STATUSES.DONE || userRef.current) registerFCMDevice(payload);
      else updateFCMInfo(payload);
      if (!interceptorInitRef.current) {
        interceptorInitRef.current = true;
        NetworkService.setupVersionInterceptor({
          fcmDeviceInfo: payload,
          store,
          checkUpdateAppVersions,
        });
      }
      //  todo: this event for type === 'message' should be removed after android changes
    } else if (type === 'message' || type === 'pushnotification') {
      // case for notification details
      getNotificationsSummary();

      // when clicked on notification, starts with `/...`
      setTimeout(() => history.push(payload.deep_link), 300);
      // toast.info(() =>
      //   CustomToastWithLink({
      //     text: t('VotingInvitation'),
      //     url: `${payload?.domain}${payload?.deep_link}`,
      //   })
      // );
    } else {
      console.warn('Unhandled bridge call' + JSON.stringify(data), data);
      // toast(JSON.stringify(payload), {
      //   autoClose: 30000,
      //   pauseOnHover: true,
      //   draggable: true,
      // });
    }
  };

  const isHigherVersion = (headerVersion: string, currentVersion: string) => {
    const headerVersionNumbers = headerVersion.split('.');
    const currentVersionNumbers = currentVersion.split('.');

    if (headerVersionNumbers.length < 3 || currentVersionNumbers.length < 3) {
      console.error(`Not a valid app version format! Can't determine if update is needed.`);
      return false;
    } else {
      let isHigher = false;
      headerVersionNumbers.forEach((headerItem, idx) => {
        isHigher = isHigher || headerItem > currentVersionNumbers[idx];
      });
      return isHigher;
    }
  };

  const checkUpdateAppVersions = (versions: {
    headerIosVersion: string;
    headerAndroidVersion: string;
    headerPwaVersion: string;
    fcmDevice?: any;
  }) => {
    const { headerIosVersion, headerAndroidVersion, headerPwaVersion, fcmDevice } = versions;

    if (
      fcmDevice?.type === DeviceTypeEnum.ios &&
      isHigherVersion(headerIosVersion, fcmDevice.app_version)
    ) {
      setUpdateModal({
        open: true,
        onClick: () => window.location.replace(process.env.REACT_APP_IOS_APP_STORE_URL as string),
        buttonText: 'Pobierz aplikację ze sklepu',
      });
    } else if (
      fcmDevice?.type === DeviceTypeEnum.android &&
      isHigherVersion(headerAndroidVersion, fcmDevice.app_version)
    ) {
      setUpdateModal({
        open: true,
        onClick: () => window.location.replace(process.env.REACT_APP_IOS_APP_STORE_URL as string),
        buttonText: 'Pobierz aplikację ze sklepu',
      });
    } else if (
      fcmDevice?.type &&
      isInWebviewByDomain() &&
      isHigherVersion(headerPwaVersion, envPWAVersion)
    ) {
      setUpdateModal({
        open: true,
        onClick: () => window.location.reload(),
        buttonText: newVersionAvailableModalConfig.buttonText,
      });
    }
  };

  const handleUpdateClick = () => {
    interceptorInitRef.current = false;
    updateModal.onClick?.();
    logout?.();
  };

  useEffect(() => {
    // window.bridge.callJs(`{"type": "message", "payload" : {"type": "lead", "id": 7, "aps": {"alert": {"body": "Test alert body!"} } }}`);
    // @ts-ignore
    window.bridge = {
      callJs,
      nativeShare,
    };
  }, []);

  useEffect(() => {
    /*if (fcmDevice && typeof fcmDevice === 'object' && RequestStatus.isDone(fcmDeviceFetchStatus))
      setTimeout(
        () => SnackbarUtils.default('Device notifications registered', { autoClose: 3000 }),
        500
      );
    else */
    if (fcmDeviceFetchStatus === REQUEST_STATUSES.ERROR) {
      toast.error('Device notifications registration failed');
    }
  }, [fcmDevice, fcmDeviceFetchStatus]);

  useEffect(() => {
    if (
      loginFetchStatus === REQUEST_STATUSES.DONE &&
      token &&
      fcmDeviceFetchStatus === REQUEST_STATUSES.NULL &&
      fcmDevice
    )
      registerFCMDevice(fcmDevice);
  }, [loginFetchStatus, token]);

  const _newVersionAvailableModalConfig = {
    ...newVersionAvailableModalConfig,
    text: 'Dostepna jest nowa wersja aplikacji. Naciśnij przycisk, aby zaktualizować aplikację do najnowszej wersji.',
  };

  return (
    <>
      {isInWebviewByDomain() && (
        <SimpleModal
          {..._newVersionAvailableModalConfig}
          open={updateModal.open}
          buttonText={updateModal.buttonText}
          handleClose={handleUpdateClick}
        />
      )}
      <>{children}</>
    </>
  );
};

export default MobileMessaging;
