import React, { FC } from 'react';
import { ClauseHeader, OrganizationType } from './VotingClause';

interface Props {
  organization: OrganizationType;
}

const VotingClauseJaworze: FC<Props> = ({ organization }) => {
  return (
    <>
      <ClauseHeader>
        KLAUZULA INFOMACYJNA DOTYCZĄCA PRZETWARZANIA DANYCH OSOBOWYCH W GŁOSOWANIU ELEKTRONICZNYM
      </ClauseHeader>

      <p>
        W związku z przetwarzaniem Pani/Pana danych osobowych informujemy, zgodnie z art. 13
        Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016r. w sprawie
        ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
        przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
        danych) (Dz. Urz. UE L z 04.05.2016 r., Nr 119, s. 1), zwanego dalej w skrócie
        <b>„RODO”</b>, że:{' '}
      </p>

      <p>
        <br />
      </p>

      <ol>
        <li>
          Administratorem Pani/Pana danych osobowych jest Wójt Gminy Jaworze, którego działa w
          Urzędzie Gminy Jaworze, ul. Zdrojowa 82, 43-384 Jaworze.
        </li>
        <p>
          <br />
        </p>
        <li>
          Administrator wyznaczył Inspektora Ochrony Danych, którym jest Pan Michał Urbaś i z którym
          może się Pani/Pan skontaktować w sprawach związanych z ochroną danych osobowych, w
          następujący sposób:
        </li>
        <p>
          1) pod adresem poczty elektronicznej: murbas@jaworze.pl
          <i>, </i>
        </p>
        <p>2) pod nr telefonu: (+48) 33 828 66 13,</p>
        <p>3) pisemnie na adres Urząd Gminy Jaworze, ul. Zdrojowa 82, 43-384 Jaworze.</p>
        <p>
          <br />
        </p>
        <li>
          Pani/Pana dane osobowe będą przetwarzane:
          <ol>
            <p>
              1) w celu realizacji konsultacji społecznych związanych z realizacją zadań Gminy
              Jaworze w ramach Budżetu na rok 2022 w trybie projektów obywatelskich, które są
              zgłaszane przez mieszkańców w ramach tzw. „Budżetu Obywatelskiego 2022”. Podstawą
              prawną przetwarzania Pana/Pani danych osobowych jest art. 6 ust. 1 lit. c) RODO
              (przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na
              administratorze) oraz art. 6 ust. 1 lit. e) RODO (przetwarzanie następuje w ramach
              wykonywania władzy publicznej) w związku z art. 5a ust. 3-7 ustawy z dnia 8 marca 1990
              r. o samorządzie gminnym;
            </p>
            <p>
              2) w celu przystąpienia do głosowania nad projektami obywatelskimi, które są zgłaszane
              przez mieszkańców w ramach tzw. „Budżetu Obywatelskiego 2022”. Podstawą prawną
              przetwarzania Pana/Pani danych osobowych jest art. 6 ust. 1 lit. a) RODO (na podstawie
              udzielonej przez Pana/Panią zgody);
            </p>
            <p>
              3) w celach archiwalnych. Podstawą prawną przetwarzania Pana/Pani danych osobowych
              jest art. 6 ust. 1 lit. c) RODO (przetwarzanie jest niezbędne do wypełnienia obowiązku
              prawnego ciążącego na administratorze) w związku z ustawą z dnia 14 lipca 1983 r. o
              narodowym zasobie archiwalnym i archiwach i aktami wykonawczymi;
            </p>
            <p>
              4) w celu realizacji obowiązków związanych z ochroną danych osobowych, np. poprzez
              tworzenie rejestrów, ewidencji, odnotowywanie informacji związanych z korzystaniem z
              praw itp. Podstawą prawną przetwarzania Pana/Pani danych osobowych jest art. 6 ust. 1
              lit. c) RODO (przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego
              na administratorze) w związku z właściwymi przepisami z zakresu ochrony danych
              osobowych.
            </p>
            <p>
              <br />
            </p>
          </ol>
        </li>
        <li>
          Z danych osobowych w wersji elektronicznej będziemy korzystać do momentu zakończenia
          procedury Budżetu Obywatelskiego Gminy Jaworze nie dłużej niż do dnia 30 czerwca 2022
          roku, a następnie w formie papierowej (wydruk z systemu elektronicznego) w zakresie
          wymaganym przez przepisy powszechnie obowiązującego prawa, w szczególności ze względu na
          cele archiwalne w interesie publicznym, cele badań naukowych lub historycznych lub cele
          statystyczne – przez okres 5 lat. Po tym okresie dokumentacja jest wybrakowana.
        </li>
        <p>
          <br />
        </p>
        <li>
          Pani/Pana dane osobowe mogą być przekazane wyłącznie podmiotom, które są uprawnione do ich
          otrzymania na podstawie przepisów prawa. Ponadto dane mogą zostać ujawnione podmiotom , z
          którymi Administrator zawarł umowę na świadczenie usług związanych z korzystaniem z
          aplikacji iVoting do głosowania elektronicznego (dostawcą aplikacji iVoting jest
          Carbonet.pl sp. z o.o., ul. Cieszyńska 365, 43-300 Bielsko-Biała, KRS: 0000695975).{' '}
        </li>
        <p>
          <br />
        </p>
        <li>
          Pani/Pana dane nie będą podlegać automatycznym sposobom przetwarzania danych opierających
          się na zautomatyzowanym podejmowaniu decyzji ani nie będą podlegać profilowaniu.
        </li>
        <p>
          <br />
        </p>
        <li>
          Pani/Pana dane nie trafią poza Europejski Obszar Gospodarczy (obejmujący Unię Europejską,
          Norwegię, Liechtenstein, Islandię).
        </li>
        <p>
          <br />
        </p>
        <li>
          W związku z przetwarzaniem Pani/Pana danych osobowych, przysługują Pani/Panu następujące
          prawa:
          <ol>
            <p>
              a. prawo do żądania od Administratora dostępu do danych osobowych oraz otrzymania ich
              kopii w przypadkach, o których mowa w art. 15 RODO;
            </p>
            <p>
              b. prawo żądania sprostowania (poprawiania) danych osobowych w przypadkach, o których
              mowa w art. 16 RODO;
            </p>
            <p>
              c. prawo żądania usunięcia danych osobowych w przypadkach określonych w art. 17 RODO;
            </p>
            <p>
              d. prawo żądania ograniczenia przetwarzania danych osobowych w przypadkach określonych
              w art. 18 RODO;
            </p>
            <p>
              e. prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, w sytuacji, gdy
              uzna Pani/Pan, że przetwarzanie danych osobowych narusza przepisy ogólnego
              rozporządzenia o ochronie danych osobowych (RODO);
            </p>
            <p>
              f. prawo do wycofania zgody na przetwarzanie danych osobowych, jeżeli przetwarzanie
              odbywa się na podstawie udzielonej przez Pana/Panią zgody.
            </p>
            <p>
              <br />
            </p>
          </ol>
        </li>
        <li>
          Podanie przez Panią/Pana danych osobowych w zakresie realizacji celów, o których mowa w
          ust. 3, jest dobrowolne. Skutkiem ich niepodania jest brak możliwości uczestnictwa w
          procedurze głosowania nad Budżetem Obywatelskim Gminy Jaworze drogą elektroniczną.
        </li>
      </ol>
    </>
  );
};

export default VotingClauseJaworze;
