import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import InputText from '../../../../components/Forms/InputText';
import { MoreHoriz } from '@material-ui/icons';
import { ActionButton } from '../../../../components/ActionButton';
import { colors } from '../../../../theme';
import DeleteOutlineIcon from '@material-ui/icons/Close';
import SimpleModal from '../../../../components/Modal/SimpleModal';
import { InfoIcon } from '../../../../components/Modal/ModalTitleIcons';
import { FormLabel } from '../../../../components/Forms/forms';
import CurrencyFlagSelect from '../../../../components/Forms/CurrencyFlagSelect';
import InputTextarea from '../../../../components/Forms/InputTextarea';
import InputImage from '../../../../components/Forms/InputImage';
import getFieldError from '../../../../utils/getFieldError';
import {
  ActionButton as AdornmentActionButton,
  Flex,
  InputsContainer,
  InputTextContainer,
  StyledIconContainerWithInput,
} from '../StyledComponents';
import Checkbox from '../../../../components/Forms/Checkbox';
import styled from 'styled-components';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { defaultAnswerImage } from '../utils/defaultValues';
import { useWatch, useFormState } from 'react-hook-form';

const AdornmentButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;

interface Props {
  name: any;
  formMethods: UseFormReturn;
  fieldsLength: number;
  schema?: any;
  answerIndex: number;

  remove(): void;
}

const AnswerInput: React.FC<Props> = (props) => {
  const { name, remove, fieldsLength, schema, formMethods, answerIndex } = props;
  const { register, formState, setValue, control, getValues, clearErrors } = formMethods;
  const [isOpenProjectInfo, setIsOpenProjectInfo] = useState<boolean>(false);
  const [isValidModalInputs, setIsValidModalInputs] = useState<boolean>(false);
  const { errors, isSubmitted } = useFormState({ control });
  const showModalErrors = !isValidModalInputs && isSubmitted;

  const watchAll = useWatch({ control });

  useEffect(() => {
    schema
      .validate(watchAll, { abortEarly: false })
      .then((valid: any) => {
        const questionNr = name?.split('questions.')?.[1]?.split('.')?.[0];
        if (
          answerIndex &&
          questionNr &&
          valid?.questions[questionNr]?.answers[answerIndex]?.project_data?.short_description
        )
          setIsValidModalInputs(true);
      })
      .catch((event: any) => {
        const path = name
          .replaceAll('.', '')
          .replace(/\d+/g, (val: any) => `[${val}].`)
          .replace(/\.\s*$/, '');
        const isValidModalInputs = !event.inner.find((error: any) => {
          return error.path.includes(path) && !error.path.includes(`${path}.text`);
        });
        setIsValidModalInputs(isValidModalInputs);
      });
  }, [watchAll]);

  const default_answer = getValues('default_answer');

  const handleDefaultAnswerCheckboxClick = () => {
    clearErrors(`${name.split('.').slice(0, 2).join('.')}.selected_default_answer`);
  };

  return (
    <>
      <InputTextContainer withIcon={fieldsLength > 1}>
        <InputText
          name={`${name}.text`}
          formRef={register}
          formState={formState}
          formType={'survey'}
          fieldError={
            getFieldError(errors, `${name}.text`) ||
            (showModalErrors && {
              message: <Trans>FillRequiredFieldsInDetails</Trans>,
            })
          }
          EndAdornment={
            <AdornmentButtonsContainer>
              {default_answer && (
                <Checkbox
                  name={`${name.split('.').slice(0, 2).join('.')}.selected_default_answer`}
                  formRef={register}
                  formState={formState}
                  formType={'survey'}
                  type={'radio'}
                  value={answerIndex}
                  onChange={handleDefaultAnswerCheckboxClick}
                />
              )}
              <AdornmentActionButton
                type={'button'}
                onClick={(event: any) => {
                  event.stopPropagation();
                  setIsOpenProjectInfo((prev: any) => !prev);
                }}
                error={showModalErrors}
                success={isValidModalInputs}
              >
                <MoreHoriz />
              </AdornmentActionButton>
            </AdornmentButtonsContainer>
          }
        />
        {fieldsLength > 1 && (
          <StyledIconContainerWithInput onClick={remove}>
            <ActionButton
              type={'button'}
              bgColor={'transparent'}
              color={colors.red}
              border={`2px solid ${colors.red}`}
            >
              <DeleteOutlineIcon />
            </ActionButton>
          </StyledIconContainerWithInput>
        )}
      </InputTextContainer>

      <SimpleModal open={isOpenProjectInfo} setOpen={setIsOpenProjectInfo} iconComponent={InfoIcon}>
        <InputsContainer>
          <InputText
            label={'Link do projektu'}
            name={`${name}.link`}
            formRef={register}
            formState={formState}
            formType={'survey'}
            fieldError={getFieldError(errors, `${name}.link`)}
          />
          <FormLabel formType={'survey'}>Koszt zadania</FormLabel>
          <Flex>
            <InputText
              type={'number'}
              name={`${name}.project_data.value`}
              formRef={register}
              formState={formState}
              formType={'survey'}
              fieldError={getFieldError(errors, `${name}.project_data.value`)}
            />
            <CurrencyFlagSelect
              formRef={register}
              formState={formState}
              name={`${name}.project_data.currency`}
              setValue={setValue}
              formType={'survey'}
              control={control}
              fieldError={getFieldError(errors, `${name}.project_data.currency`)}
            />
          </Flex>
          <InputTextarea
            label={'Skrócony opis projektu'}
            name={`${name}.project_data.short_description`}
            formRef={register}
            formState={formState}
            formType={'survey'}
            fieldError={getFieldError(errors, `${name}.project_data.short_description`)}
            control={control}
            schema={schema}
            setValue={setValue}
          />
          <FormLabel formType={'survey'}>Miniaturka do zadania</FormLabel>
          <InputImage
            name={`${name}.image`}
            formRef={register}
            formState={formState}
            control={control}
            fieldError={getFieldError(errors, `${name}.image`)}
            helperText={'Rekomendowany format 1:1. Wymagane rozszerzenie jpg lub png.'}
            setValue={setValue}
            defaultImage={defaultAnswerImage}
          />
        </InputsContainer>
      </SimpleModal>
    </>
  );
};

export default AnswerInput;
